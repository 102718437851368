<template>
<div class="foot">
        <footer class="bg-light text-center text-lg-start">
        <!-- Copyright -->
        <div class="text-center p-3" style="color:white;background: linear-gradient(45deg,#29536e 0%,#29536e 60%,#34607c 60%,#34607c 100%);">
            © 2022 Copyright:
            <a class="text-white" href="#">www.changwan.com.tw</a> 
        </div>
        <!-- Copyright -->
        </footer>

    </div>
</template>
<script>
export default {
  name: 'Efootbar',
  props: {
    msg: String
  }
}
</script>