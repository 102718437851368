<template>
  <div class="pro">
      <b-card header="個人資料設定">        
                <a-alert v-show="errors!==''" :message="errors" :type="mtyps" show-icon />
                  <div class="text-center">
                  <b-avatar button  :src="xurl" @click="toggleShow" variant="primary" text="FF" size="8rem" class="align-baseline"></b-avatar>
                  </div>               
                   <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
                   <blockquote class="blockquote mb-0">
                    <a-button type="primary" @click="enterIconLoading" :loading="loadinged" :disabled="user.data.user.utoken!=''" :icon="user.data.user.utoken!=''?'lock':'unlock'">
                      Line 綁定狀態 : {{user.data.user.utoken==''?'未綁定':'綁定'}}
                    </a-button>  
                    <img style="margin-left:0.9rem;margin-top: -0.5rem;" width="26px" :src="catImg(user.data.user.utoken!=''?'lock.png':'unlock.png')" alt="載入圖片">                
                    <div style="margin-top:0.9rem;" v-if="user.data.user.utoken=='' && luser.v1!=''"> 
                      <b-card
                        no-body
                        style="max-width: 34rem;"
                      >
                        <template #header>
                          <h6 class="mb-0"><span style="color:crimson;font-size: 14px;" v-text="luser.v1=='0'?'底下Line帳號未註冊過，可以拿來綁定，請按下確認按鈕':'底下Line帳號已經註冊過，請按下取消按鈕，請重新使用其它Line帳號來已綁定'" /></h6>
                        </template>
                        <b-card-body>
                          <b-card-title>
                            <a-avatar
                            slot="avatar"
                            :src="luser.v3.trim()!='0'?'https://profile.line-scdn.net/'+luser.v3:''"
                            :size="30"
                          />  
                          </b-card-title>
                          <b-card-sub-title class="mb-2">
                            <span v-text="luser.v4" />
                          </b-card-sub-title>                        
                        </b-card-body>
                        <b-card-footer>
                          <h6 class="mb-0">
                          <div v-if="luser.v1=='0'" ><a href="#"  @click="upLine()" class="btn btn-success">確認</a><span style="color:crimson;font-size: 14px;" v-text="' (該Line 帳號未被綁定，請按 確認 按鈕，才算Line綁定成功)'"></span></div>
                          <div v-if="luser.v1=='1'"><a href="#"  @click="clearns()" class="btn btn-warning">取消</a><span style="color:crimson;font-size: 14px;" v-text="' (✘ 該Line帳號已被綁定，請按取消在使用其它Line帳號)'"></span></div>                          
                          </h6>
                        </b-card-footer>
                      </b-card>
                    </div>

                    <div style="margin-top:7px;"></div>    
           
                   <b-form @submit="onSubmit">
                         <b-form-group id="input-group-1" class="w-75">
                         <label for="input-1"><code> * </code>顯示名稱 :</label>
                        <b-form-input
                           id="input-1"
                           v-model="form.display"
                           type="text"
                           placeholder="輸入顯示名稱 "
                           aria-autocomplete="none"
                           role="presentation"
                           autocomplete="off"
                           required
                           ></b-form-input>
                        </b-form-group>
                        <b-form-group
                           id="input-group-2"
                           label-for="input-2"   
                           :description=" form.email!=='' ? '我們永遠不會與其他人分享您的電子郵件.':''"
                           class="w-75"
                           >
                           <label for="input-group-2"> Email : </label>
                           <b-form-input
                           id="input-2"
                           v-model="form.email"
                           type="email"
                           placeholder="輸入 Email "
                           aria-autocomplete="none"
                           role="presentation"
                           autocomplete="off"                          
                           ></b-form-input>
                       </b-form-group>
                       <b-form-group 
                        id="input-group-3" 
                        :description="result? '該帳號已存在, 請使用其它帳號':''" 
                        label-for="input-3"
                        class="w-75"
                        >
                         <label for="input-group-3"><code> * </code>登入帳號 : </label>
                           <b-form-input
                           id="input-3"
                           v-model="form.account"
                           @change="checkAccount"
                           aria-autocomplete="none"
                           role="presentation"
                           autocomplete="off"
                           placeholder="輸入帳號"
                           :disabled="true"
                           required
                           ></b-form-input>
                       </b-form-group>

                      <b-form-group label="性別" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          id="radio-group-4"
                          v-model="form.sex"
                          :options="options"
                          :aria-describedby="ariaDescribedby"
                          name="radio-options"
                        ></b-form-radio-group>
                      </b-form-group>

                   <b-form-group
                           id="input-group-5"
                           label-for="input-5"     
                           class="w-75"                                                          
                         >
                          <label for="input-group-5">生日 : </label>
                       <b-form-input
                           id="input-5"
                           v-model="form.birth"
                           type="date"
                           placeholder="請選擇日期 " 
                           autocomplete="off"                           
                           ></b-form-input>
                    </b-form-group>            

                   <b-form-group
                           id="input-group-6"
                           label-for="input-6"     
                           class="w-50"                                                          
                         >
                          <label for="input-group-6">電話 : </label>
                       <b-form-input
                           id="input-6"
                           v-model="form.mphone"
                           type="number"
                           placeholder="輸入電話 "  
                           autocomplete="off"                           
                           ></b-form-input>
                    </b-form-group>       

                   <b-form-group
                           id="input-group-7"
                           label-for="input-7"     
                           class="w-50"                                                          
                         >
                          <label for="input-group-7">手機 : </label>
                       <b-form-input
                           id="input-7"
                           v-model="form.hphone"
                           type="number"   
                           placeholder="輸入手機 "   
                           autocomplete="off"
                           ></b-form-input>
                    </b-form-group>        
                    
                   <b-form-group
                           id="input-group-8"
                           label-for="input-8"                                                            
                         >
                         <label for="input-group-8"><code> * </code>地址 : 
                          <a-button @click="getAddress()" type="link" size="small"><a-icon :style="{ fontSize: '20px', color: '#08c' }" type="home" />(選取地址)</a-button>
                          <span style="color:crimson;font-size: 16px;" v-text="'⚠街道路號可選填'"></span>
                        </label>
                       <b-form-input
                           id="input-8"
                           v-model="form.address"
                           type="text"   
                           placeholder="輸入地址 "
                           autocomplete="off"
                           required
                           ></b-form-input>
                    </b-form-group>                                                       
                        <a-button 
                        type="primary"
                        html-type="submit"
                        size="large"
                        :loading="iconLoading"                        
                        :disabled=" form.display===''  || form.account==='' || form.address==='' "
                        block
                        >存檔</a-button>       
                                                                                       
                   </b-form>
                   </blockquote>
                </b-card> 
                <my-upload 
                      field="imgfile"
                      @crop-success="cropSuccess"
                      @crop-upload-success="cropUploadSuccess"
                      @crop-upload-fail="cropUploadFail"
                      v-model="shows"
                      langType='zh-tw'
                      method='POST'
                      :width="300"
                      :height="300"
                      :url="uploadurl"
                      :params="params"
                      :headers="head"
                      img-format="png"

                  ></my-upload>


          <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
            <div class="text-center">
                <b-spinner  variant="primary" label="Text Centered"></b-spinner>
                <strong >Loading....</strong>
            </div>
          </b-modal> 

          <b-modal
            ref="my-address"
            id="modal-2"
            title="地址選取"
            @hide="onHide"
            centered
            hide-footer
            hide-header
          >
            <div class="text-right">
              <a-button @click="closeModal()" type="link" size="small">(關閉)</a-button>
              <Addresscom @toaddress="toaddress" />
            </div>
          </b-modal>

  </div>
</template>
<script>
import router from '../router'
import { userService } from '../_services/'
import {mapState,mapActions,mapMutations} from 'vuex';
import Addresscom from "../components/Addresslist.vue"
//import {VueAvatarEditor} from 'vue-avatar-editor-improved'
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import store from "../store/index";
export default {
    name : 'Profile',
    components: {
       // VueAvatarEditor: VueAvatarEditor
       'my-upload': myUpload,
       Addresscom,
    },    
    data(){
        return{
            form: {
                display : '',
                email: '',
                account: '',
                password: '',
                password2: '',
                sex:'',
                birth:'',     
                mphone:null,
                hphone:null,   
                address : '', 
                url : '',  
                citys:'', 
                villages:'', 
                towns:'',        
                },
            purl:'',
            iconLoading: false, 
            displayname:'',
            loading: false,
            loadinged:false,
            result:null,
            errors:'',
            mtyps:'error',
            uploadurl: process.env.VUE_APP_URL +'user/image',  
            xurl :null, 
            shows: false,
            imgDataUrl: '',  
            options: [
              { text: '男性', value: 'male' },
              { text: '女性', value: 'female' },
            ]             

        }
    },
    methods:{
        ...mapActions('account',['logout','checktoken','checkAccounts']),
        ...mapMutations('account',['updateProfile','setpage','setClearns','updateLine']),
      async onSubmit(event) {
            event.preventDefault()
              this.timer()
              this.iconLoading= true
              //JSON.stringify(this.form)            
              var playload ={
                use : false,
                priUrl : this.form.url,
                email : this.form.email,
                password : this.form.password,
                username : this.form.account,
                display : this.form.display,
                sex:this.form.sex,
                birth:this.form.birth,   
                mphone:this.form.mphone,
                hphone:this.form.hphone,     
                address: this.form.address,
                citys:this.form.citys,   
                villages:this.form.villages,
                towns:this.form.towns, 
              }
              //console.log(playload)
              this.updateProfile(playload)
              //alert(JSON.stringify(this.user.data.user))
              await userService.upprofile(this.user.data.user)
                .then(
                    rs => {
                        if(rs.status===201){
                          //router.replace('/')
                          this.iconLoading = false
                          this.errors = "存檔成功"
                          this.mtyps = "success"
                          this.$message.success('存檔成功');
                        }                      
                    },
                ); 
            
        },               
        
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
                this.handleBackdrop();
            }
        }, 

        toaddress(val,city,village,town){
            this.form.address = val
            this.form.citys=city
            this.form.villages = village
            this.form.towns = town
        },                   
        filluser(){
          if(this.user){
            this.form.display = this.user.data.user.display_name!=undefined?this.user.data.user.display_name:''
            this.form.url = this.user.data.user.pri_url!=undefined?this.user.data.user.pri_url:''
            this.xurl =  this.form.url!=undefined?this.form.url:''
            this.form.email = this.user.data.user.email!=undefined?this.user.data.user.email:''         
            this.form.account = this.user.data.user.username!=undefined?this.user.data.user.username:''               
            this.form.sex = this.user.data.user.sex!=undefined?this.user.data.user.sex:''  
            this.form.birth = this.user.data.user.birth!=undefined?this.user.data.user.birth:''
            this.form.mphone = this.user.data.user.mphone!=undefined?this.user.data.user.mphone:''
            this.form.hphone = this.user.data.user.hphone!=undefined?this.user.data.user.hphone:''   
            this.form.address = this.user.data.user.address!=undefined?this.user.data.user.address:''
            this.form.password = this.user.data.user.password!=undefined?this.user.data.user.password:''  
            this.form.citys = this.user.data.user.citys!=undefined?this.user.data.user.citys:''   
            this.form.villages = this.user.data.user.villages!=undefined?this.user.data.user.villages:''
            this.form.towns = this.user.data.user.towns!=undefined?this.user.data.user.towns:'' 
            //this.form.password2 = this.user.data.user.password!=undefined?this.user.data.user.password:''          
          }

        },        

        async handleChange({file}) {                 
                if(file.status ==='done'){
                    this.xurl = file.response.Location  
                    this.form.url = this.xurl
                    this.$refs['my-loading'].hide()
                }
        },  
        
            beforeUpload(file) {
                this.errors=''
                //this.$emit('upmessage',null)
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                return new Promise((resolve, reject) => {
                  if(!isJpgOrPng) {
                    //this.$message.error('您的檔案必須是 JPG or PNG!');
                    this.errors = '您的檔案必須是 JPG or PNG!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
                  reject(file);
                  } else if(!isLt2M) {
                    //this.$message.error('您的檔案必須是小於 2MB!');
                    this.errors = '您的檔案必須是小於 2MB!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是小於 2MB!')
                  reject(file);
                  }else {
                  this.$refs['my-loading'].show()
                  resolve(file);
                  }
              });
            },         

        async checkAccount (){
         if(this.form.account){
           this.result = await userService.checkAcct(this.form.account)
            .then(
                rs => {
                    return rs.data.verify
                },
            );
          if(this.result){
              this.form.account = ''
          }      
         } 
        },   

        async handleFiles(element) {
            let file = element.target.files[0];            
            await userService.upload(file)
          },

        closeModal() {
          this.$refs["my-address"].hide();
        }, 
        getAddress() {
          this.$refs["my-address"].show();
        },                 


			toggleShow() {
				this.shows = !this.shows;
			},
            /**
			 * crop success
			 *
			 * [param] imgDataUrl
			 * [param] field
			 */
			cropSuccess(imgDataUrl, field){
				console.log('-------- crop success --------');
				this.imgDataUrl = imgDataUrl;
        this.xurl = this.imgDataUrl
        this.xurl =  this.form.url
        console.log(field)
        this.errors = "圖片調整後, 請記得按下表單的存檔按鈕, 大頭貼才算存檔成功!"
        this.mtyps = "warning"        
        this.$message.warning('調整後, 請記得按下表單的存檔按鈕, 大頭貼才算存檔成功!');
			},
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field){
				console.log('-------- upload success --------');
				console.log(jsonData);
				console.log('field: ' + field);
         this.xurl = jsonData.Location  
         this.form.url = this.xurl
        //this.$message.warning('調整後, 請記得按下表單的存檔按鈕, 大頭貼才算存檔成功!');
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
			},


      timer() {
        this.$refs['my-loading'].show()
        setTimeout(() => {
            this.$refs['my-loading'].hide()
        }, 2000);

        }, 
        
      catImg(imgs) {
          return require(`../assets/${imgs}`);
      }, 
      
      async enterIconLoading(){
        this.loadinged= true
        let obj = { id: "Edit" };
        await this.setorgpage(obj)       
        if(store.state.account.targetpage=='Edit'){
          let  temp_form = document.createElement("form");
           temp_form.action = process.env.VUE_APP_URL +'gotoauthOpenIDpage';      
           temp_form.target = "_self";
           temp_form.method = "post";      
           temp_form.style.display = "none";
           let  opt = document.createElement("input"); 
           opt.type = "text";
           opt.name = "chatbot";
           opt.value = store.state.account.targetpage
           temp_form.appendChild(opt);    
           document.body.appendChild(temp_form);
           // 提交表單      
           temp_form.submit();      
        }        
      },
      setorgpage(obj) {
      return new Promise(resolve => {
        resolve(this.setpage(obj));
      });
    }, 
    
    clearns(){
      this.setClearns();
    },

    async upLine(){
      //this.user.data.user.id
      this.loadinged= true 
      this.updateLine({utoken:this.luser.v2,display:this.luser.v4,priUrl:'https://profile.line-scdn.net/'+this.luser.v3}) 
      await userService.upprofile(this.user.data.user)
        .then(
            rs => {
                if(rs.status===201){
                  this.loadinged = false                  
                  this.$message.success('存檔成功');
                  this.clearns()
                }                      
            },
        );        
    }

    },
    computed:{
      ...mapState('account',['status','user','luser']),
      headers(){   
        return{                    
          "X-Requested-With":null,
          "Authorization": "Bearer "+this.status.token,
        }
      },

      head(){
        return{        
          "Authorization": "Bearer "+this.status.token,
        }
      },

      params() {
        return{
          name: 'imgfile'
        }
      },


    },
    mounted(){ 
        if(this.user==null){
          router.replace('/')
        }else{
          this.checktoken()   
          this.filluser()        
        }

    }            
}
</script> 
<style scoped>
</style>

