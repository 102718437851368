<template>
    <div class="pro">
        <b-card header="密碼設定">        
                <a-alert v-show="errors!==''" :message="errors" :type="mtyps" show-icon />  
                                           
                <b-form @submit="onSubmit">                                                    
                   <b-form-group id="input-group-9" class="w-60" label-for="input-9">
                            <label for="input-group-9"><code> * </code>密碼 : </label>
                             <b-input-group  >
                                <b-form-input
                                id="input-9"
                                :type="view1?'password':'text'"
                                v-model="form.password"
                                autocomplete="off"
                                placeholder="輸入密碼"
                                class="w-50"  
                                required
                                ></b-form-input>
                                <b-input-group-append>
                                <b-button variant="info" @click="view1=!view1">view</b-button>
                                </b-input-group-append>
                            </b-input-group>
                         </b-form-group>
                        <b-form-group id="input-group-10" :description="form.password===form.password2 && (form.password2!=='' && form.password1!=='')  ? '謝謝您,的輸入':form.password2=''" class="w-60" label-for="input-10">
                             <label for="input-group-10"><code> * </code>再次輸入密碼 :</label>
                            <b-input-group  >
                                <b-form-input
                                    id="input-10"
                                    :type="view2?'password':'text'"
                                    v-model="form.password2"
                                    autocomplete="off"
                                    placeholder="請再輸入密碼一次"
                                    class="w-50"  
                                    required
                                ></b-form-input>
                                <b-input-group-append>
                                <b-button variant="info" @click="view2=!view2">view</b-button>
                                </b-input-group-append>
                            </b-input-group>                            
                             
                         </b-form-group>                          
  
                          <a-button 
                          type="primary"
                          html-type="submit"
                          size="large"
                          :loading="iconLoading"                        
                          :disabled=" form.password==='' || form.password2==='' "
                          block
                          >存檔</a-button>
                          <div style="height:24rem;"></div>                                                              
                     </b-form>
                  </b-card> 

            <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
              <div class="text-center">
                  <b-spinner  variant="primary" label="Text Centered"></b-spinner>
                  <strong >Loading....</strong>
              </div>
            </b-modal> 
    </div>
  </template>
  <script>
  import router from '../router'
  import { userService } from '../_services/'
  import {mapState,mapActions,mapMutations} from 'vuex';
  export default {
      name : 'Password',
      components: {

      },    
      data(){
          return{
              form: {
                  password: '',
                  password2: '',
                  },
              view1:true,
              view2:true,
              iconLoading: false, 
              loading: false,
              result:null,
              errors:'',
              mtyps:'error',
              shows: false,          
  
          }
      },
      methods:{
          ...mapActions('account',['logout','checktoken','checkAccounts']),
          ...mapMutations('account',['updatePassword','setpage','setClearns','updateLine']),
        async onSubmit(event) {
              event.preventDefault()
                this.timer()
                this.iconLoading= true
                //JSON.stringify(this.form)            
                var playload ={
                  password : this.form.password,
                }
                this.updatePassword(playload)
                console.log(this.user)
                await userService.upprofile(this.user.data.user)
                  .then(
                      rs => {
                          if(rs.status===201){
                            this.iconLoading = false
                            this.errors = "存檔成功"
                            this.mtyps = "success"
                            this.$message.success('存檔成功');
                          }                      
                      },
                  ); 
              
          },               
          
          onHide(evt) {
              if(evt.trigger === "backdrop"){
                  evt.preventDefault();
                  this.handleBackdrop();
              }
          },         
          filluser(){
            if(this.user){
              this.form.password = this.user.data.user.password!=undefined?this.user.data.user.password:''          
            }
  
          },        
        timer() {
          this.$refs['my-loading'].show()
          setTimeout(() => {
            this.$refs['my-loading'].hide()
          }, 2000);

          }, 
      },
      computed:{
        ...mapState('account',['status','user','luser']),
      },
      mounted(){ 
          if(this.user==null){
            router.replace('/')
          }else{
            this.checktoken()   
            this.filluser()        
          }
  
      }            
  }
  </script> 
  <style scoped>
  </style>
  
  