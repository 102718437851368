<template>
  <div class="act">
    <div class="d-flex flex-column min-vh-100">
      <Menubar msg="test" @query="query" ref="Mbar" />
        <div style="height:20px;"></div>
        <div class="row justify-content-center">
          <div class="col-md-7" >          
              <b-card no-body>
                <b-tabs pills card vertical>
                  <b-tab title="個人資料設定" active><b-card-text>
                      <div class="text-left">
                          <Profile />
                      </div>              
                  </b-card-text></b-tab>

                  <b-tab title="密碼設定"><b-card-text>
                      <div class="text-left">
                          <Password />
                      </div>              
                  </b-card-text></b-tab>

                </b-tabs>
              </b-card>
          </div>
        </div>
    <div style="height:10px;"></div>
    <div class="wrapper flex-grow-1"></div>      
    <Efootbar /> 
    </div>
  </div>
</template>
<script>
//import Evenmenubar from '@/components/Evenmenubar.vue'
import Efootbar from '@/components/Efootbar.vue'
import Menubar from '@/components/Menubar.vue'
import Profile from './Profile'
import Password from './Password'
import router from '../router'
import {mapState,mapActions} from 'vuex';
export default {
  name: 'Edit',
  components: {
    //Evenmenubar,
    Menubar,
    Efootbar,
    Profile,
    Password,
  },
  data() {
    return {
      title:{title:'個人檔案',logo:"exclamation-circle-fill"},     
    };
  },
  methods:{
        ...mapActions('account',['checktoken']), 
        query(rs){
          console.log(rs)
        }, 
    },
    computed:{
      ...mapState('account',['user'])
    },    
    mounted(){ 
        if(this.user==null){
          router.replace('/')
        }else{
          this.checktoken() 
        }
        this.$refs["Mbar"].closebar(false); 
    }      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

div.ex3 {
  width:800px;
  height: 460px;
  overflow: auto;
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}
</style>